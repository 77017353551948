import React from 'react'

const Header = () => {
    return (
        <div className='header'>
       
            <marquee>
            <p>VARDAN HOSPITAL TRAUMA AND DIAGNOSTIC CENTER     –  24×7  Hospital Ambulance  /  Emergency Helpline No. : +91 8922940607</p>
            </marquee>

           
       
    </div>
    )
}

export default Header