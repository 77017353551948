import React, { useState } from 'react';



const Head = () => {
   
    return (
        <div className='head'>
            <div className='head2'>
                <p>Managing Director: +91 8922940607</p>
            </div>
            <div className='head2'>
                <p>Hospital Contact Number: +91 8922940607</p>
            </div>
            <div className='head2'>
                <p>Public inter college k saamne. Faizabad road. Shahganj. Jaunpur</p>
            </div>
            
          
        </div>
    );
};

export default Head;
