import React from 'react'
import { Carousel } from 'react-bootstrap';
import banner1 from './img/banner1.jpg';
import banner2 from './img/banner3.jpg';
import banner3 from './img/banner4.jpg';


const Carousal = () => {
  return (
    <Carousel fade interval={3000} className="carousel-container">
      <Carousel.Item>
        <img className="d-block w-100" src={banner1} alt="General Checkup" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner2} alt="General Surgery" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={banner3} alt="Laparoscopic Surgery" />
      </Carousel.Item>
     
    </Carousel>
  )
}

export default Carousal