import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>

            <div className='footer'>
                <div className='foot'>
                    <h1>Location</h1>
                
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14336.206424376256!2d82.681145!3d26.0645698!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39905b2333f99df5%3A0xb7950a89048655ab!2sShahganj%20Public%20Inter%20College%20Shahganj%20Jaunpur!5e0!3m2!1sen!2sin!4v1719662211305!5m2!1sen!2sin" ></iframe>
                </div>
                <div className='foot'>
                    <h1>Facility </h1>
                    <Link to='' className='best'> <p>Best I.C.U Facility</p></Link>
                    <Link to='' className='best'> <p>Advanced Technology</p></Link>
                    <Link to='' className='best'> <p>Highly Trained Staff ENT</p></Link>
                    <Link to='' className='best'> <p>Plastic & Cosmetic Surgery</p></Link>
                    <Link to='' className='best'> <p>Medical Infrastructure</p></Link>


                </div>
                <div className='foot'>
                    <h1>Treatment</h1>
                    <Link to='/Generlchp' className='best'> <p>General Checkups, Psychiatry</p></Link>
                    <Link to='/Generalsurgy' className='best'> <p>General Surgery</p></Link>
                    <Link to='/LaparoscopicSurgery' className='best'> <p>Laparoscopic Surgery</p></Link>
                    <Link to='/CancerSurgery' className='best'> <p>Cancer Surgery</p></Link>
                    <Link to='/Chemotherapy' className='best'> <p>Chemotherapy</p></Link>
                </div>
                <div className='foot'>
                    <h1>Reach Us </h1>
                   
                    <p>Doctor: +91 8922940607</p>
                    <p>
                    Public inter college k saamne. Faizabad road. Shahganj. Jaunpur</p>
                    <p>vardanhospitalshahganj<br/>@gmail.com</p>

                  
                </div>
            </div>

            <div className='disclamer'>
                <p>@ 2024 VARDAN HOSPITAL TRAUMA AND DIAGNOSTIC CENTER  Designed by Creative Digital World </p>
            </div>


        </>
    )
}

export default Footer