import React, { useEffect, useState } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';

const Data = () => {



    const [getuserdata, setuserdata] = useState([]);
    console.log(getuserdata);

    const getdata = async (e) => {
        const res = await fetch("/getdata", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }

        );
        const data = await res.json();
        console.log(data);
        if (!data || res.status === 404) {
            alert("No record avalable");
            console.log("error")
        }
        else {
            setuserdata(data);
            console.log(data)
            console.log("Data has been retrive");
        }
    }

    useEffect(() => {
        getdata();
    }, []);


    const deleteuser = async (id) => {
        const res2 = await fetch(`/deleteuser/${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const deletedata = await res2.json();
        console.log(deletedata);
        if (res2.status === 404 || !deletedata) {
            console.log("error")
        }
        else {
            console.log("user Deleted");
            getdata();

            Swal.fire({
                icon: 'success',
                title: 'User Deleted Successfully',
                showConfirmButton: false,
                timer: 1000
            });
        }
    }

    return (


        <>

            <div className='container'>

                <center>
                    <h2 className="card-title">VARDAN HOSPITAL TRAUMA AND DIAGNOSTIC CENTER</h2>
                    <h5 className="card-title">Appointment Leads</h5>
                </center>

                <br/>
                <table class="table  table-bordered">
                    <thead >
                        <tr className="table-dark" style={{ textAlign: 'center' }}>
                            <th scope="col"> Booking Id</th>
                            <th scope="col"> Patient Name</th>
                            <th scope="col"> Patient Email</th>
                            <th scope="col"> Patient Mobile</th>
                            <th scope="col"> Appointment Date</th>
                            <th scope="col"> User Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getuserdata.map((element, id) => {
                                return (
                                    <tr key={element._id}>
                                        <td>{id + 1}</td>
                                        <td>{element.name}</td>
                                        <td>{element.email}</td>
                                        <td>{element.mobile}</td>
                                        <td>{element.date}</td>
                                        <td className='d-flex justify-content-between'>
                                            <NavLink to={`view/${element._id}`}><button className='btn btn-success'><RemoveRedEyeIcon /> View Detail</button></NavLink>
                                            {/* <NavLink to={`edit/${element._id}`}> <button className='btn btn-primary'><CreateIcon /></button></NavLink> */}
                                            <NavLink> <button className='btn btn-danger' onClick={() => deleteuser(element._id)}><DeleteOutlineIcon /> Delete Lead</button></NavLink>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}

export default Data