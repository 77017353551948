import React from 'react'
import hospital from './img/banner1.jpg';

const Avicenna = () => {
  return (
    <>


      <div className='avicenna'>

        <div className='avicenna2'>
          <img src={hospital} />
        </div>


        <div className='avicenna2'>

          <div className='fast'>
            <p>Advanced Medical Infrstructure Expert Team Of Specialist</p>
          </div>
          <div className='fast'>
            <p>Highly Trained Staff Trusted Care From Leading Specialist</p>
          </div>
          <div className='fast'>
            <p>One of the Best Hospital In Lucknow Get Treated by The best , care of Patient</p>
          </div>
          
        </div>





      </div>

    </>
  )
}

export default Avicenna