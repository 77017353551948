import React from 'react'
import { Link } from 'react-router-dom'
import about from './img/var1.jpg'

const About = () => {
    return (

        <>
            <div className='about'>
                <div className='about2'>

                    <h1>WELCOME TO  VARDAN HOSPITAL TRAUMA AND DIAGNOSTIC CENTER </h1>
                    <p>VARDAN HOSPITAL, a leading multi-super Speciality hospital in Lucknow committed to providing comprehensive and high-quality healthcare services. Our facility is equipped with state-of-art technology and our team of highly skilled medical professionals is dedicated to delivering exceptional patient care. Our charges is also very affordable.
                        "We at VARDAN are COMMITTED to achieve EXCELLENCE in QUALITY of services and STRIVE for continuous improvement for providing best health care services and complete customer satisfaction.</p>


                    <Link to='/Register'><button className="btn btn-primary">Contact US </button></Link>

                </div>
                <br/>
                <div className='about2'>
                    <img src={about} />
                </div>
            </div>


            {/* <div className='emergncy'>
                <h1>OPEN 24*7  HRS [ EMERGENCY SERVICES ]</h1>
            </div> */}

            <div class="icon-bar">
                <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                <a href="#" class="twitter"><i class="fa fa-instagram"></i></a>
                <a href="#" class="google"><i class="fa fa-whatsapp"></i></a>
                <a href="#" class="youtube"><i class="fa fa-youtube-play"></i></a>
            </div>


        </>
    )
}

export default About